import React, { createContext, useEffect, useState } from 'react';

export const SiteContext = createContext({});

export const SiteProvider = ({ children }) => {
  const [reconnect, setReconnect] = useState(false);
  const user =
    localStorage?.getItem('user') && JSON.parse(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user'))
      : {};
  const [userData, setUserData] = useState(user);
  const [socket, setSocket] = useState(null);
  const [webSocketData, setWebSocketData] = useState({});
  const [forceLogout, setForceLogout] = useState(false);
  const initializeWebSocket = (globalWsLink) => {
    const newSocket = new WebSocket(globalWsLink);

    newSocket.onopen = (event) => console.debug('[SOCKET OPEN]', event);

    newSocket.onmessage = (event) => {
      if (event && event.data && event.data.includes("status_code': 401")) {
        console.debug('[SOCKET ERROR]', event);
        setForceLogout(true);
      }
      if (event && event.data && !event.data.includes("status_code': 401")) {
        setForceLogout(false);
        const parsedWsData = JSON.parse(event.data) || {};

        if (parsedWsData) {
          parsedWsData['date'] = new Date();
        }

        console.debug('[SOCKET DATA]', parsedWsData);
        setWebSocketData(parsedWsData);
      }
    };

    newSocket.onerror = (event) => console.debug('[SOCKET ERROR]', event);

    newSocket.onclose = (event) => {
      console.debug('[SOCKET CLOSE]', event);
      setReconnect(true); // Attempt to reconnect on close
    };

    setSocket(newSocket);
  };

  useEffect(() => {
    const access_token = localStorage.getItem('access_token') || '';
    const globalWsLink = `${process.env.REACT_APP_GLOBAL_WS_END_POINT}?access_token=${access_token}`;
    access_token && initializeWebSocket(globalWsLink);

    const focusListener = () => {
      if (socket?.readyState === WebSocket.CLOSED) {
        console.log('[WEBSOCKET RE-CONNECTING ON FOCUS]', socket.readyState);
        initializeWebSocket(globalWsLink);
      }
    };
    window.addEventListener('focus', focusListener);

    return () => {
      window.removeEventListener('focus', focusListener);
      socket?.close();
    };
  }, [reconnect]);

  return (
    <SiteContext.Provider
      value={{
        socket,
        webSocketData,
        setWebSocketData,
        reconnect,
        setReconnect,
        userData,
        setUserData,
        forceLogout,
        setForceLogout,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
