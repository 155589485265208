import React from 'react';
import { ButtonBase } from '@mui/material';
import errorIcon from '../../Assets/Images/error.png';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Caught by Error Boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='error-boundary-wrapper'>
          <div className='error-title'>
            <img src={errorIcon} alt='error' />
            <h1>Unexpected error occurred, Please back to Home or refresh the page.</h1>
          </div>
          <ButtonBase className='btns theme-solid' onClick={() => (window.location.href = '/')}>
            <span className='mdi mdi-arrow-left mr-1' />
            Back to home
          </ButtonBase>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
