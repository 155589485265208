import React from 'react';
import ForbiddenImg from '../../Assets/Images/403.png';
import './Forbidden.scss';

const ForbiddenLayout = () => {
  return (
    navigator.onLine && (
      <div className='no-data-result'>
        <img src={ForbiddenImg} alt='forbidden' />
      </div>
    )
  );
};
export default ForbiddenLayout;
