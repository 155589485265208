import React from 'react';
import pageNotFoundImg from '../../Assets/Images/404.gif';
import './NotFound.scss';

const NotFoundLayout = () => {
  return (
    navigator.onLine && (
      <div className='no-data-result'>
        <img src={pageNotFoundImg} alt='page not found' />
      </div>
    )
  );
};
export default NotFoundLayout;
