import { PATHS_HIDE_SIDE_MENU } from 'enums';
import _ from 'lodash';
import moment from 'moment';

export function getObjectDifference(array1, array2) {
  const result = [];

  array1.forEach((item1) => {
    const match = array2.find((item2) => _.isEqual(item1, item2));
    if (!match) {
      result.push(item1);
    }
  });

  array2.forEach((item2) => {
    const match = array1.find((item1) => _.isEqual(item1, item2));
    if (!match) {
      result.push(item2);
    }
  });
  return result;
}

export function inPast(start, end, type = 'seconds') {
  if (!(start && end)) {
    return -1;
  }
  const date1 = moment(start);
  const date2 = moment(end);
  return date1.diff(date2, type) < 0;
}

export function classNames(...args) {
  const classes = [];

  args?.forEach((arg) => {
    if (typeof arg === 'string' || typeof arg === 'number') {
      classes.push(arg);
    } else if (typeof arg === 'object') {
      for (const key in arg) {
        if (arg.hasOwnProperty(key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  });

  return classes.join(' ');
}

export function handleError({ showError, errorResponse, fallbackMessage = 'An error occurred' }) {
  if (Array.isArray(errorResponse?.detail)) {
    const errorMessages = errorResponse.detail
      .map((error) => `<li>${error.loc.join(' > ')}:${error.msg}</li>`)
      .join('');
    const toastContent = `<div style="max-height: 300px; overflow-y: auto;"><ul>${errorMessages}</ul></div>`;
    showError(
      <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: toastContent }} />,
    );
  } else {
    showError(
      errorResponse?.error || errorResponse?.[0] || errorResponse?.message || fallbackMessage,
    );
  }
}

export function getUserFromLocalStorage() {
  const storedUser = localStorage.getItem('user');
  return storedUser ? JSON.parse(storedUser) : null;
}

export function getCompanyLabelsFromLocalStorage() {
  const storedCompanyLabels = localStorage.getItem('company_labels');
  return storedCompanyLabels ? JSON.parse(storedCompanyLabels) : null;
}

export function getCurrentLanguage() {
  const localization = localStorage.getItem('localization');
  const lang = localization && JSON.parse(localization)?.currentLanguage;
  return lang || '';
}

export function resetKeys(obj, restValue, ...keys) {
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      resetKeys(obj[key], restValue, ...keys);
    } else if (keys.includes(key)) {
      obj[key] = restValue;
    }
  }
  return obj;
}

export const shouldHideSideMenu = (pathname) => {
  return Object.values(PATHS_HIDE_SIDE_MENU).some((path) => pathname.includes(path));
};

export function isValueDate(value) {
  const date = new Date(value);
  return !isNaN(date.getTime());
}
