export const SystemActionsEnum = {
    edit: {
        key: "edit",
        icon: "mdi mdi-pencil",
        color: "c-gray-dark",
        bgColor: "bg-gray",
        value: "Edit"
    },
    delete: {
        key: "delete",
        icon: "mdi mdi-trash-can",
        color: "c-warning",
        bgColor: "bg-warning",
        value: "Delete"
    },
    view: {
        key: "view",
        icon: "mdi mdi-eye",
        color: "c-primary",
        bgColor: "bg-primary",
        value: "View"
    },
    moreActions: {
        key: "moreActions",
        icon: "mdi mdi-dots-vertical",
        color: "c-gray-dark",
        bgColor: "bg-primary",
        value: "More Actions"
    },
    download: {
        key: "download",
        icon: "mdi mdi-tray-arrow-down",
        color: "c-primary",
        bgColor: "bg-primary",
        value: "Download"
    },
    simulate: {
        key: "simulate",
        icon: "mdi mdi-flask-outline",
        color: "c-primary",
        bgColor: "bg-primary",
        value: "Simulate"
    }
};
