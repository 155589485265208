export const PaginationEnum = {
    5: {
        key: 5,
        value: 5
    },
    10: {
        key: 10,
        value: 10
    },
    25: {
        key: 25,
        value: 25
    },
    50: {
        key: 50,
        value: 50
    },
    100: {
        key: 100,
        value: 100
    },
    150: {
        key: 150,
        value: 150
    }
};
