export const SORT_TYPE = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

export const PATHS_HIDE_SIDE_MENU = Object.freeze({
  API_DOCUMENTATION: 'api-documentation',
});

export const HTTP_EXCEPTION_CODE_401_PATHS = Object.freeze({
  API_DOCUMENTATION: 'api-documentation',
});
