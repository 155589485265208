import React from 'react';
import ServerErrorImg from '../../Assets/Images/500.gif';
import './ServerError.scss';

const ServerErrorayout = () => {
  return (
    navigator.onLine && (
      <div className='no-data-result'>
        <img src={ServerErrorImg} alt='forbidden' />
      </div>
    )
  );
};
export default ServerErrorayout;
