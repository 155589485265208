import loadable from '@loadable/component';
import comapnySchemaIcon from '../../../Assets/icons/company-schema-red.svg';
import customizationIcon from '../../../Assets/icons/customization.svg';
import tokensIcon from '../../../Assets/icons/tokens.svg';

const CompaniesPageView = loadable(() =>
  import('../../../Views/Home/companies-page/CompaniesPage.View'),
);
const CompanySchemaPageView = loadable(() =>
  import('../../../Views/Home/company-schema/CompanySchemaPage.View.jsx'),
);
const CompanySchemaEntitiesPage = loadable(() =>
  import(
    '../../../Views/Home/company-schema/schema-views/CompanySchemaEntities/CompanySchemaEntitiesPage.View.jsx'
  ),
);
const CompanySchemaEntitiesManagementPage = loadable(() =>
  import(
    '../../../Views/Home/company-schema/schema-views/CompanySchemaEntities/CompanySchemaEntitiesManagementPage.jsx'
  ),
);
const CompanySchemaTransformationManagementPage = loadable(() =>
  import(
    '../../../Views/Home/company-schema/schema-views/CompanySchemaTransformation/CompanySchemaTransformationManagementPage.jsx'
  ),
);
const CompanySchemaTransformationPage = loadable(() =>
  import(
    '../../../Views/Home/company-schema/schema-views/CompanySchemaTransformation/CompanySchemaTransformationPage.View.jsx'
  ),
);
const CompanySchemaDataGeneratePage = loadable(() =>
  import(
    '../../../Views/Home/company-schema/schema-views/CompanySchemaDataGenerate/CompanySchemaDataGeneratePage.View.jsx'
  ),
);
const CompanySchemaDataGenerateManagementPage = loadable(() =>
  import(
    '../../../Views/Home/company-schema/schema-views/CompanySchemaDataGenerate/CompanySchemaDataGenerateManagementPage.View.jsx'
  ),
);
const CompanySchemaDataGenerateListPage = loadable(() =>
  import(
    '../../../Views/Home/company-schema/schema-views/CompanySchemaDataGenerate/CompanySchemaDataGenerateListPage.View.jsx'
  ),
);
const UsersPageView = loadable(() => import('../../../Views/Home/users-page/UsersPage.View'));
const TokenPageView = loadable(() => import('../../../Views/Home/company-token/TokenPage.View'));
const UsersManagementView = loadable(() =>
  import('../../../Views/Home/users-page/UsersManagement.View'),
);
const ProductsPageView = loadable(() =>
  import('../../../Views/Home/products-page/ProductsPage.View'),
);
const ProductsManagementView = loadable(() =>
  import('../../../Views/Home/products-page/ProductsManagement.View.jsx'),
);
const CustomizationPage = loadable(() =>
  import('../../../Views/Home/customization-company/CustomizationPage.View'),
);
const ForbiddenLayout = loadable(() => import('../../../Layouts/Forbidden/Forbidden.Layout'));
const ServerErrorayout = loadable(() => import('../../../Layouts/ServerError/ServerError.Layout'));

const HomeRoutes = [
  {
    id: 1,
    path: '/companies-page',
    name: 'Companies',
    component: CompaniesPageView,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: 'mdi mdi-store-outline',
    isDisabled: false,
    isRecursive: false,
    isHidden: false,
    isExact: true,
    children: [],
  },
  {
    id: 2,
    path: '/products',
    name: 'Products',
    component: ProductsPageView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['COMPANY_ADMIN', 'USER'],
    icon: 'mdi mdi-view-dashboard-outline',
    isDisabled: false,
    isHidden: false,
    isRecursive: false,
    isExact: true,
    children: [],
  },
  {
    id: 3,
    path: '/products-management',
    name: 'Product Management',
    component: ProductsManagementView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['COMPANY_ADMIN', 'USER'],
    icon: 'mdi mdi-view-dashboard-outline',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
  },
  {
    id: 4,
    path: '/users',
    name: 'Users',
    component: UsersPageView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['COMPANY_ADMIN', 'SUPER_USER'],
    icon: 'mdi mdi-account-multiple-outline',
    isDisabled: false,
    isRecursive: false,
    isExact: true,
    isHidden: false,
    children: [],
  },
  {
    id: 12,
    path: '/company-tokens',
    name: 'API Keys',
    component: TokenPageView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['COMPANY_ADMIN'],
    img: tokensIcon,
    isDisabled: false,
    isRecursive: false,
    isExact: true,
    isHidden: false,
    children: [],
  },
  {
    id: 5,
    path: '/users-management',
    name: 'User Management',
    component: UsersManagementView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['COMPANY_ADMIN', 'SUPER_USER'],
    icon: 'mdi mdi-account-group',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
  },
  {
    id: 6,
    path: '/comapny-schema',
    name: 'Company Schema',
    component: CompanySchemaPageView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: '',
    img: comapnySchemaIcon,
    isDisabled: false,
    isRecursive: false,
    isHidden: true,
    isExact: true,
    children: [],
  },
  {
    id: 7,
    path: '/comapny-schema-entities',
    name: 'Company Schema Entities',
    component: CompanySchemaEntitiesPage,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: '',
    img: comapnySchemaIcon,
    isDisabled: false,
    isRecursive: false,
    isHidden: true,
    isExact: true,
    children: [],
  },
  {
    id: 8,
    path: '/comapny-schema-transformation',
    name: 'Company Schema Transformation',
    component: CompanySchemaTransformationPage,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: '',
    img: comapnySchemaIcon,
    isDisabled: false,
    isRecursive: false,
    isHidden: true,
    isExact: true,
    children: [],
  },
  {
    id: 9,
    path: '/company-schema-generate-data',
    name: 'Company Schema Generate Data',
    component: CompanySchemaDataGeneratePage,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: '',
    img: comapnySchemaIcon,
    isDisabled: false,
    isRecursive: false,
    isHidden: true,
    isExact: true,
    children: [],
  },
  {
    id: 10,
    path: '/company-schema-generate-data-management',
    name: 'Company Schema Generate Data Management',
    component: CompanySchemaDataGenerateManagementPage,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: '',
    img: comapnySchemaIcon,
    isDisabled: false,
    isRecursive: false,
    isHidden: true,
    isExact: true,
    children: [],
  },
  {
    id: 10,
    path: '/company-schema-generate-data-list',
    name: 'Company Schema Generate Data List',
    component: CompanySchemaDataGenerateListPage,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: '',
    img: comapnySchemaIcon,
    isDisabled: false,
    isRecursive: false,
    isHidden: true,
    isExact: true,
    children: [],
  },
  {
    id: 11,
    path: '/comapny-schema-entities-management',
    name: 'Company Schema Entities Management',
    component: CompanySchemaEntitiesManagementPage,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: '',
    img: comapnySchemaIcon,
    isDisabled: false,
    isRecursive: false,
    isHidden: true,
    isExact: true,
    children: [],
  },
  {
    id: 12,
    path: '/comapny-schema-transformation-management',
    name: 'Company Schema Transformation Management',
    component: CompanySchemaTransformationManagementPage,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: '',
    img: comapnySchemaIcon,
    isDisabled: false,
    isRecursive: false,
    isHidden: true,
    isExact: true,
    children: [],
  },
  {
    id: 13,
    path: '/customization-company',
    name: 'Customization Company',
    component: CustomizationPage,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: ['SUPER_USER'],
    icon: '',
    img: customizationIcon,
    isDisabled: false,
    isRecursive: false,
    isHidden: true,
    isExact: true,
    children: [],
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: ForbiddenLayout,
    layout: '/home',
    default: false,
    isRecursive: true,
    authorize: true,
    roles: [],
    isRoute: true,
    children: [],
  },
  {
    path: '/500',
    name: 'Server Error',
    component: ServerErrorayout,
    layout: '/home',
    default: false,
    isRecursive: true,
    authorize: true,
    roles: [],
    isRoute: true,
    children: [],
  },
];

export default HomeRoutes;
