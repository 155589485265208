import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import NotFoundLayout from 'Layouts/NotFound/NotFound.Layout';
import ForbiddenLayout from 'Layouts/Forbidden/Forbidden.Layout';
import ServerErrorayout from 'Layouts/ServerError/ServerError.Layout';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { SiteContext } from 'context/SiteContext';

export const SwitchRouteComponent = memo(({ routes }) => {
  const { userData } = useContext(SiteContext);
  const companyPermissionKeys = userData?.authorization?.company;
  /**
   * @author Manaf Hijazi (mhijazi@beyond.ai)
   * @Description this method is to get the latest active child
   * of the route or the component or if the route not exists
   * at all then redirect to the first default
   * P.S:- need to enhance in future projects to check if
   * the first default is can be accessed or not
   * if not then redirect to the login page or
   * the authorized page like landing page
   * directly before even enter the init of the pages
   */
  const getCurrentRouteGroup = useMemo(
    () => (currentRoutes) => {
      const localPath = window.location.pathname || '';
      const routeIndex = currentRoutes.findIndex(
        (f) =>
          (f.isExact && localPath === f.layout + f.path) ||
          (!f.isExact && localPath.includes(f.layout + f.path)),
      );

      if (routeIndex !== -1) {
        if (currentRoutes[routeIndex].component) return currentRoutes;
        if (currentRoutes[routeIndex].children && currentRoutes[routeIndex].children.length > 0)
          return getCurrentRouteGroup(currentRoutes[routeIndex].children);
      }

      const currentDefault = currentRoutes.find((f) => f.default);

      if (
        currentDefault &&
        currentDefault.isRecursive &&
        currentDefault.children &&
        currentDefault.children.length > 0
      )
        return getCurrentRouteGroup(currentDefault.children);

      return currentDefault;
    },
    [],
  );

  const [route, setRoute] = useState(getCurrentRouteGroup(routes) || []);
  const history = useHistory();

  useEffect(() => {
    setRoute(getCurrentRouteGroup(routes));
  }, [getCurrentRouteGroup, history, routes]);

  return (
    <Switch>
      {routes &&
        Array.isArray(routes) &&
        routes.map((value, key) => {
          if (!value.isRoute || !value.component) return null;
          const permissionKey = value?.permissionKey;
          if (!value.defaultPermissions) {
            if (
              permissionKey &&
              companyPermissionKeys &&
              !companyPermissionKeys[permissionKey]?.enabled
            ) {
              return (
                <Route
                  exact={value.isExact}
                  component={ForbiddenLayout}
                  key={`publicRoute${key + 1}`}
                  path={value.layout + value.path}
                />
              );
            } else {
              return (
                <Route
                  exact={value.isExact}
                  component={value.component}
                  key={`publicRoute${key + 1}`}
                  path={value.layout + value.path}
                />
              );
            }
          }
        })}

      {route && !Array.isArray(route) && <Redirect from='**' to={route.layout + route.path} />}

      <Route path='/home/403' component={ForbiddenLayout} />
      <Route path='/home/500' component={ServerErrorayout} />

      <Route component={NotFoundLayout} />
      <Route path='**' component={NotFoundLayout} />
      <Route path='*' element={<Redirect to='/404' />} />
      <Route path='notfound' component={NotFoundLayout} />
      <Route path='*' element={<Redirect to='/notfound' replace />} />
    </Switch>
  );
});

SwitchRouteComponent.displayName = 'SwitchRouteComponent';

SwitchRouteComponent.propTypes = {
  routes: PropTypes.instanceOf(Array).isRequired,
};
