import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import './Loader.Style.scss';

const StyledCircularProgress = (props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={70}
        value={100}
        thickness={5}
        variant='determinate'
        {...props}
      />
      <CircularProgress
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#70b6c1' : '#308fe8'),
          animationDuration: '700ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={70}
        disableShrink
        thickness={5}
        variant='indeterminate'
        {...props}
      />
    </Box>
  );
};

export const LoaderComponent = ({ isLoading }) =>
  isLoading && (
    <div className='loader-wrapper'>
      <Backdrop open>
        <StyledCircularProgress data-testid={'loader-component'} />
      </Backdrop>
    </div>
  );

LoaderComponent.propTypes = {
  isLoading: PropTypes.bool,
};
LoaderComponent.defaultProps = {
  isLoading: false,
};
