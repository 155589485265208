import React, { createContext, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

const OnlineStatusContext = createContext({});

const OnlineStatusProvider = ({ children }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        function updateOnlineStatus() {
            setIsOnline(navigator.onLine);
        }

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
        }, [navigator.onLine]);

    return (
        <OnlineStatusContext.Provider value={isOnline}>
            <div>
                <div>
                    {!isOnline ? <div style={{
                        position: "fixed",
                        top: 0,
                        zIndex: 1000,
                        width: '100%',
                    }}>
                    <div
                        style={{
                        display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        color: 'white',
                            fontWeight: 'bold',
                            fontSize: '10px' ,
                            backgroundColor: '#C70039',
                            padding: 5
                    }}>
                        <Icon icon="heroicons-solid:exclamation" width="20" height="20"  color="white" style={{ margin: '0 15px'}} />
                                                    It seems you're offline. Some features may not be available until you're back online.
                        
                    </div>
                    </div> : null  }
                </div>
                {children}
            </div>
        </OnlineStatusContext.Provider>
        );
};

export default OnlineStatusProvider;
