import React, { Suspense, useEffect } from 'react';
import axios from 'axios';
import { AppRoutes } from './Routes';
import { MiddlewareHelper } from './Helpers';
import { SwitchRouteComponent } from './Components';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SiteProvider } from './context/SiteContext';
import OnlineStatusProvider from './Hooks/useOnlineStatus';
import { TranslationProvider } from 'context/useTranslation';

const App = () => {
  // React Query Client initialization
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
      },
    },
  });

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_FA_END_POINT;
  }, []);

  return (
    <OnlineStatusProvider>
      <SiteProvider>
        <TranslationProvider>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Suspense fallback='...'>
                <MiddlewareHelper />
              </Suspense>
              <SwitchRouteComponent routes={AppRoutes} />
            </Router>
          </QueryClientProvider>
        </TranslationProvider>
      </SiteProvider>
    </OnlineStatusProvider>
  );
};

export default App;
