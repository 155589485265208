/**
 * Function that clears the Local Storage
 */
const clearLocalStorage = () => {
  const localStorageItems = [
    'user',
    'isLoggedIn',
    'access_token',
    'notifications-list',
    'notifications-read-time',
    'webSocketMessages',
    'iconify-count',
    'iconify-version',
    'localization',
    'user_permission',
    'company_labels',
  ];

  localStorageItems.forEach((item) => {
    localStorage.removeItem(item);
  });
};

// Export service
export const storageService = {
  clearLocalStorage,
};
