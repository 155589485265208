import { toast } from 'react-toastify';

export const showSuccess = (message, options = { type: 'success' }) => {
  return toast(message, options);
};
export const showWarning = (message, options = { type: 'warning' }) => {
  return toast(message, options);
};
export const showError = (message, options = { type: 'error' }) => {
  if (
    message &&
    Array.isArray(message) &&
    message[0] &&
    message[0].props &&
    message[0].props.children
  ) {
    const combinedMessage = message.map((item) => item.props.children).join('<br><br>');

    toast(
      <div
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{ __html: combinedMessage }}
      />,
      options,
    );
  } else if (message && Array.isArray(message)) message.map((message) => toast(message, options));
  else if (
    message &&
    message.props &&
    message.props.children &&
    Array.isArray(message.props.children)
  )
    message.props.children.map((item) => toast(item, { ...options, autoClose: 7000 }));
  else toast(message, options);
};
