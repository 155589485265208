import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCompanyLabelsFromLocalStorage, getCurrentLanguage } from 'utils/utils';

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState('en');
  useEffect(() => {
    const companyLabels = getCompanyLabelsFromLocalStorage();
    const lang = getCurrentLanguage();
    setLanguage(lang);
    setTranslations(companyLabels);
  }, []);
  const t = (sectionKey, key) => {
    return translations?.[sectionKey]?.[key]?.[language] || '';
  };
  return (
    <TranslationContext.Provider
      value={{ t, setTranslations, translations, language, setLanguage }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const { t, setTranslations, translations, language, setLanguage } =
    useContext(TranslationContext);
  return { t, setTranslations, translations, language, setLanguage };
};
