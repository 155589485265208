import { createTheme } from '@mui/material/styles';

export const mdTheme = createTheme({
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
    },
    h2: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
    },
    h3: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
    },
    h4: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
    },
    h5: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
    },
    h6: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
    },
    subtitle1: {
      fontSize: '12px',
      fontStyle: 'normal',
      lineHeight: '20px',
      fontWeight: '600',
    },
    subtitle2: {
      fontSize: '12px',
      fontStyle: 'normal',
      lineHeight: '20px',
      fontWeight: '500',
    },
    button: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  palette: {
    primary: {
      main: '#1773c4',
    },
    secondary: {
      main: '#70B6C1',
    },
    success: {
      main: '#159d40',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'auto',
          },
        },
      },
    },
  },
});
