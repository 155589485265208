export const OPTIMIZER_STATUS_ENUM = Object.freeze({
    DRAFT: "DRAFT",
    NEW: "NEW",
    RUNNING: "RUNNING",
    STOPPED: "STOPPED",
    COMPLETED: "COMPLETED",
    FAIL: "FAIL",
    NO_RESULT: "NO_RESULT"
});

export const OPTIMIZER_LABEL_ENUM = Object.freeze({
    DRAFT: "Draft",
    NEW: "New",
    RUNNING: "Running",
    STOPPED: "Stopped",
    COMPLETED: "Completed",
    FAIL: "Failed",
    NO_RESULT: "No result"
});

export const OPTIMIZER_EXECUTE_OPTIONS = Object.freeze({
    RUN: "RUN",
    STOP: "STOP"
});