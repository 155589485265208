export const EDA_STATUS_ENUM = Object.freeze({
  DRAFT: 'DRAFT',
  NEW: 'NEW',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  COMPLETED: 'COMPLETED',
  FAIL: 'FAIL',
  NO_RESULT: 'NO_RESULT',
});

export const EDA_LABEL_ENUM = Object.freeze({
  DRAFT: 'Draft',
  NEW: 'New',
  READY: 'Ready',
  RUNNING: 'Running',
  STOPPED: 'Stopped',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  NO_RESULT: 'No result',
});

export const EDA_EXECUTE_OPTIONS = Object.freeze({
  RUN: 'RUN',
  STOP: 'STOP',
});
