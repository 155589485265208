export const CREATION_STRATEGIES = ['Fully_Automated', 'Estimator_Builder', 'Notebook_Template'];

export const missingValuesTypeEnum = {
  INSPECTION: 'INSPECTION',
  REPLACEMENT: 'REPLACEMENT',
  DROP_COLUMNS: 'DROP_COLUMNS',
  DROP_ROWS: 'DROP_ROWS',
};

export const EstimatorBuilderStepsEnum = {
  INITIALIZATION: 'INITIALIZATION',
  SELECTION: 'SELECTION',
  MISSING_VALUE: 'MISSING_VALUES',
  OUTLIER: 'OUTLIER',
  TRANSFORMATION: 'TRANSFORMATION',
  MACHINE_LEARNING_MODEL: 'MACHINE_LEARNING_MODEL',
  TRAIN_TEST_SPLIT: 'TRAIN_TEST_SPLIT',
  FINISH: 'FINISH',
};

export const EstimatorBuilderRunningStatusEnum = {
  RUNNING: 'Running',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  DRAFT: 'Draft',
  STOPPED: 'Stopped',
  SUBMITTED: 'Submitted',
};
